import React from 'react'
import Marquee from 'react-fast-marquee'
function MarqueeFunction() {
	return (
		<div className='top'>
			<Marquee>
				<div className='marq'>Grand Opening January 13</div>
				<div className='marq'>&middot;</div>
				<div className='marq'>Grand Opening January 13</div>
				<div className='marq'>&middot;</div>
				<div className='marq'>Grand Opening January 13</div>
				<div className='marq'>&middot;</div>
				<div className='marq'>Grand Opening January 13</div>
				<div className='marq'>&middot;</div>
				<div className='marq'>Grand Opening January 13</div>
				<div className='marq'>&middot;</div>
				<div className='marq'>Grand Opening January 13</div>
				<div className='marq'>&middot;</div>
			</Marquee>
		</div>
	)
}

export default MarqueeFunction
