import React from 'react'
import generator from 'blobshape'
import animate from './utils/animation'
function Blob() {
	const interval = React.useRef(null)
	const [path, setPath] = React.useState(null)
	const [windowSize, setWindowSize] = React.useState(0)
	const generateBlob = (props) =>
		generator({
			...props,
			growth: 6,
			edges: 12,
			size: windowSize * 1.5,
		})

	const setSize = (e) => {
		if (window.innerWidth <= 768) {
			setWindowSize(window.innerWidth * 1.25)
		} else {
			setWindowSize(window.innerHeight)
		}
	}

	React.useEffect(() => {
		setSize()
		window.addEventListener('resize', setSize)

		if (windowSize > 0) {
			const p = generateBlob().path
			setPath(p)

			clearInterval(interval.current)
			interval.current = setInterval(() => {
				const newPath = generateBlob().path
				setPath(newPath)
				animate(newPath)
			}, 2000)
		}

		return () => {
			clearInterval(interval.current)
			window.removeEventListener('resize', setSize)
		}
	}, [windowSize])

	return (
		<div className='blob'>
			<svg
				width='100%'
				height='100%'
				viewBox={`0 0 ${windowSize} ${windowSize}`}
				preserveAspectRatio='none'
				xmlns='http://www.w3.org/2000/svg'
				//className='svg'
			>
				<defs>
					<clipPath id='clip'>
						<path
							id='blob'
							d={path}
							fill='white'
							transform={`translate(-${
								windowSize <= 768 ? windowSize / 3 : 0
							},${windowSize <= 768 ? 0 : -windowSize / 6})`}
						/>
					</clipPath>
				</defs>
			</svg>
		</div>
	)
}

export default Blob
