import { useState } from 'react'
import Blob from './Blob'
import Marquee from './Marquee'
import Circle from './Circle'
import './App.css'

function App() {
	const [open, setOpen] = useState(false)
	return (
		<div className='full'>
			<Blob />
			<div className='bg'></div>
			<div className='circle'>
				{/*<img src='/images/info.svg' />*/}
				<Circle />
			</div>
			<div className='logo'>
				<img src='/images/yolk-logo.svg' />
			</div>
			<div className='directions'>
				<a href='#' onClick={() => setOpen(true)}>
					Directions
				</a>
			</div>
			<Marquee />
			<div className={`modal ${open && 'modal-open'}`}></div>
		</div>
	)
}

export default App
