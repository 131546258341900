import { useRef, useEffect } from 'react'
import { useTexture, CubeCamera } from '@react-three/drei'
import { Canvas, useFrame } from '@react-three/fiber'
import { BackSide, MathUtils } from 'three'
const Yolk = () => {
	return (
		<CubeCamera>
			{(texture) => (
				<mesh>
					<sphereGeometry args={[3.05, 64, 64]} />
					<meshStandardMaterial
						color='yellow'
						metalness={0.9}
						roughness={0.1}
						envMap={texture}
						clearcoat={10}
					/>
				</mesh>
			)}
		</CubeCamera>
	)
}

const Store = () => {
	const ref = useRef()
	const group = useRef()
	const mouse = useRef({ x: 0, y: 0 })
	const texture = useTexture('/bg.jpg')
	useFrame(({ clock }) => {
		//ref.current.rotation.y = clock.getElapsedTime() / 4
		group.current.rotation.x = MathUtils.lerp(
			group.current.rotation.x,
			mouse.current.y * 0.001,
			0.1
		)
		group.current.rotation.y = MathUtils.lerp(
			group.current.rotation.y,
			mouse.current.x * 0.001,
			0.1
		)
	})

	const onDocumentMouseMove = (event) => {
		const windowHalfX = window.innerWidth / 2
		const windowHalfY = window.innerHeight / 2

		mouse.current.x = event.clientX - windowHalfX
		mouse.current.y = event.clientY - windowHalfY

		console.log(mouse.current.x)
	}

	useEffect(() => {
		document.addEventListener('mousemove', onDocumentMouseMove)
		return () =>
			document.removeEventListener('mousemove', onDocumentMouseMove)
	}, [])

	return (
		<group ref={group}>
			<mesh ref={ref} rotation={[0, -1.65, 0]}>
				<sphereGeometry args={[5, 64, 64]} />
				<meshBasicMaterial side={BackSide} map={texture} />
			</mesh>
		</group>
	)
}

export default function Circle() {
	return (
		<Canvas>
			<Store />
			<Yolk />
			<directionalLight position={[5, 5, 5]} intensity={5} />
			{/*<Environment preset='studio' />*/}
		</Canvas>
	)
}
